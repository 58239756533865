import React from "react"
import { Spacer } from "@styles/utilityClasses"
import { MediumDisplay, LargeBody } from "@styles/typography"
import { Container } from "@styles/utilityClasses"
import Seo from "@components/seo"
import styled from "styled-components"
import { graphql } from "gatsby"
// import { GatsbyImage,getImage } from "gatsby-plugin-image"

const CeoLetterPage = () => {
  // const { heading } = data
  // const { title, slug, image } = data.sanityAvatar

  return (
    <>
      <Seo title="Ceo Letter" />
      <Container>
        <Spacer />
        <Title>letter from the ceo</Title>
        <Spacer />
        <Paragraph>
Our services focus on the delivery of automated data-collection and operational process pipelines directed at companies with proven business models that are lagging in technical adoption.
<br/>
<br/>
We are positioned at the intersection of digital services and activist investing – we integrate automated pipelines that have quantifiable impacts on the bottom line of proven companies. As such, we only source projects from vetted applications / consultation, and our own research for our business transformation program.
For clients who don't fit our criteria, we offer a la carte services though our <a href="https://agency.zenith-research.ca">agency</a> to help them get to the point where they can benefit from our business transformation program.
<br/>
<br/>
In the age of digitization, only businesses with the highest informational bandwidth will succeed. Few organizations (such as google, amazon, meta) have truly leveraged the global availability of data resulting in significant competitive imbalances. By combining the technical expertise of Zenith and the domain expertise of our clients we will achieve our mission of eliminating informational monopolies to empower a competitive digital economy.
        </Paragraph>
        <Credits>
          <span>gabriel atkinson, ceo</span>
          {/* <Avatar image={getImage(image.asset)} alt={title} /> */}
          {/* <a href={slug.current}>{title}</a>, ceo */}
        </Credits>
        <Spacer />
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    sanityCeoLetter {
      heading
      letter
      text {
        children {
          text
        }
      }
    }
    sanityAvatar(title: { eq: "gabe atkinson" }) {
      title
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData(width: 48, placeholder: BLURRED, fit: FILL)
        }
      }
    }
  }
`

export default CeoLetterPage

const Title = styled(MediumDisplay)`
  width: 100%;
  text-align: center;
  justify-self: center;
`

const Paragraph = styled(LargeBody)`
  text-align: center;
  margin-inline: auto;
  color: rgba(255, 255, 255, 0.8);
  width: min(100% - 2rem, 1200px);
  margin-inline: auto;
  a {
    text-decoration: underline;
  }
  color: ${p => p.theme.bodyParagraphColor};
`

// const Avatar = styled(GatsbyImage)`
//   width: 48px;
//   height: 48px;
//   border-radius: 50%;
// `

const Credits = styled.div`
  text-align: center;
  margin-top: 2rem;
  color: ${p => p.theme.bodyParagraphColor};
  font-family: var(--body-text-regular), sans-serif;
  vertical-align: middle;

  a {
    margin-left: 1rem;
    color: ${p => p.theme.bodyParagraphColor};
    text-decoration: none;
  }
`
